@import "variables";

.intro {
	background-color: $gris;
	padding: 100px 0;
	.pic-thumb {
		.fig1 {
			z-index: 1;
			left: -15px;
			top: -15px;
		}
		.fig2 {
			z-index: 10;
			bottom: -15px;
			right: -15px;
		}
	}
}

.nosotros {
	padding: 100px 0;
	.pic-thumb {
		.fig1 {
			z-index: 1;
			right: -15px;
			top: -15px;
		}
		.fig2 {
			z-index: 10;
			bottom: -15px;
			left: -15px;
		}
	}
}

.servicios {
	background-color: $celeste;
	padding: 100px 0;
	.pic-thumb {
		.fig1 {
			z-index: 1;
			left: -15px;
			bottom: -15px;
		}
		.fig2 {
			z-index: 10;
			right: -15px;
			top: -15px;
		}
	}
}

.convenios {
	padding: 100px 0 80px;
}
