.intro {
  background-color: #f4f4f4;
  padding: 100px 0;
}

.intro .pic-thumb .fig1 {
  z-index: 1;
  top: -15px;
  left: -15px;
}

.intro .pic-thumb .fig2 {
  z-index: 10;
  bottom: -15px;
  right: -15px;
}

.nosotros {
  padding: 100px 0;
}

.nosotros .pic-thumb .fig1 {
  z-index: 1;
  top: -15px;
  right: -15px;
}

.nosotros .pic-thumb .fig2 {
  z-index: 10;
  bottom: -15px;
  left: -15px;
}

.servicios {
  background-color: #5da6db;
  padding: 100px 0;
}

.servicios .pic-thumb .fig1 {
  z-index: 1;
  bottom: -15px;
  left: -15px;
}

.servicios .pic-thumb .fig2 {
  z-index: 10;
  top: -15px;
  right: -15px;
}

.convenios {
  padding: 100px 0 80px;
}

/*# sourceMappingURL=index.ca67cda1.css.map */
